import React, { useEffect, useState } from 'react'

import { updateProfile } from '../../services/userService'
import { showToast } from '../../services/toastService'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../../utils'
import { getInterests } from '../../services/interestService'

export default function PersonalizeTags() {
  const navigate = useNavigate()
  const [selectedTags, setSelectedTags] = useState([])

  const [tags, setTags] = useState([]) //Initialize as empty array

  const fetchTags = async () => {
    try {
      const data = await getInterests()
      // Make sure data exists and is properly transformed to array of names
      if (data && Array.isArray(data)) {
        setTags(data.map((tag) => tag.name))
      } else {
        setTags([])
        console.error('Invalid data format received:', data)
      }
    } catch (error) {
      console.error('Error fetching tags:', error)
      setTags([])
    }
  }

  /* const fetchTags = async () => {
    const data = await getInterests()
    setTags(data.map((tag) => tag.name))
  } */

  useEffect(() => {
    fetchTags()
  }, [])

  const handleSubmit = async (e) => {
    if (selectedTags.length < 3) {
      showToast('Please select at least 3 tags', 'error')
      return
    }
    if (selectedTags.length > 6) {
      showToast('Please select at most 6 tags', 'error')
      return
    }
    e.preventDefault()
    const res = await updateProfile({ interests: selectedTags.join(',') })
    if (res.success) {
      showToast('Profile created successfully', 'success')
      navigate('/promoter')
    }
  }

  return (
    <div className="container mx-auto my-auto" style={{ height: '100vh' }}>
      <div className="my-auto mt-4 d-flex justify-content-center align-items-center">
        <div className="col-lg-6">
          <img
            src="/assets/images/logo.svg"
            alt="GreatNight Logo"
            id="logo"
            onClick={() => navigate('/promoter/')}
            style={{ height: '47px' }}
          />
          <div className="mt-8">
            <h2 className="text-large font-bold">What are you into?</h2>
            <h4 className="text-sm">Select at least 3 things that you are interested in.</h4>
          </div>
          <main>
            <div className="flex flex-wrap gap-3 mt-4 text-sm">
              {tags.map((tag, index) => (
                <div key={`${tag}_${index}`} className="grid-span-1 text-sm ">
                  <div
                    onClick={() => {
                      if (selectedTags.find((t) => t === tag)) {
                        setSelectedTags(selectedTags.filter((t) => t !== tag))
                      } else {
                        setSelectedTags([...selectedTags, tag])
                      }
                    }}
                    className={classNames(
                      'rounded p-1 text-sm cursor-pointer text-center',
                      selectedTags.find((t) => t === tag) ? 'bg-purple-300 hover:bg-purple-400' : 'bg-[#e0d8d8] '
                    )}
                  >
                    <h4 className="text-xs p-1">{tag}</h4>
                  </div>
                </div>
              ))}
            </div>
            {/* Next Buttons */}
            <div className="flex flex-wrap justify-center ">
              <button
                style={{ borderRadius: '25px', width: '225px' }}
                className="bg-purple-500 rounded text-white text-xs text-center self-center px-3 py-2 my-2 mx-2 mt-4"
                onClick={handleSubmit}
              >
                <span className="text-white">Next</span>
              </button>
              <div className="bg-white-500 border-black rounded text-white text-xs text-center self-center px-3 py-2 my-2 mx-2 mt-8">
                <a href="/promoter" className="text-gray-600">
                  Skip
                </a>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
