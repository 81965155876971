import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EventDetails from '../components/event/details/EventDetails'
import { createVibe, getEventDetailsBySlug, rsvpForEvent, setReminder } from '../services/eventService'

function EventDetailsContainer() {
  const { eventId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [eventDetails, setEventDetails] = useState(null)
  const [loading, setLoading] = useState(true)
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')
  const [savedEvent, setSavedEvent] = useState(false)
  const [isJoinedChatRoom, setJoinedChatRoom] = useState(false)

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const eventData = await getEventDetailsBySlug(eventId)
        setEventDetails(eventData.data)
        const vibes = eventData.vibes
        setSavedEvent(vibes.some((vibe) => vibe.user_id === user.userId))
        setJoinedChatRoom(eventData.is_joined_chatroom)

        const pathParts = location.pathname.split('/')
        const influencerIndex = pathParts.indexOf('influencer')
        const influencerId = influencerIndex !== -1 ? pathParts[influencerIndex + 1] : null

        if (influencerId) {
          localStorage.setItem(
            'invite',
            JSON.stringify({ influencerId: influencerId, event: eventId, eventName: eventData.data.name })
          )
        } else {
          localStorage.setItem(
            'invite',
            JSON.stringify({ influencerId: null, event: eventId, eventName: eventData.data.name })
          )
        }
        setLoading(false)
      } catch (error) {
        console.error('Error fetching event details:', error)
        setLoading(false)
      }
    }

    fetchEventDetails()
    // eslint-disable-next-line
  }, [eventId])

  const handleShareEvent = () => {
    const shareableLink = `${window.location.origin}/event-goer/event/${eventDetails.slug || eventId}/influencer/${user.userId}`

    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this event',
          text: `Join ${eventDetails.name} event`,
          url: shareableLink
        })
        .then(() => console.log('Shared successfully'))
        .catch((error) => console.error('Error sharing:', error))
    } else if (navigator.clipboard) {
      navigator.clipboard
        .writeText(shareableLink)
        .then(() => {
          console.log('Shareable Link:', shareableLink)
          setMessage('Link copied to clipboard')
          setShowToast(true)
        })
        .catch((error) => console.error('Error copying link:', error))
    } else {
      console.log('Shareable Link:', shareableLink)
    }
  }

  const handleRsvpForRegisteredUser = async () => {
    setLoading(true)

    const rsvp = await rsvpForEvent()

    if (rsvp?.data?.message === 'User has already RSVP') {
      navigate('/promoter/my-event')
    } else {
      setLoading(false)
      setJoinedChatRoom(true)
      setSavedEvent(true)
    }
    setLoading(false)
  }

  const handleSaveVibe = async () => {
    if (user?.userId) {
      setLoading(true)
      const data = {
        eventSlug: eventId,
        userId: user.userId
      }
      const vibe = await createVibe(data)
      const redirectPath = `/promoter/event/vibes`
      setLoading(false)
      navigate(redirectPath, { state: { user: user.data, vibes: vibe.vibes } })
    } else {
      navigate('/login')
    }
  }

  const setReminderHandler = async () => {
    // Retrieve existing array from localStorage
    let reminders = JSON.parse(localStorage.getItem('reminders')) || []
    const data = {
      eventId: eventDetails?.event_id
    }
    const response = await setReminder(data)
    setMessage(response.message)

    // Add new item to the array using spread operator
    reminders = [...reminders, { eventId: eventDetails?.event_id }]

    // Save updated array back to localStorage
    localStorage.setItem('reminders', JSON.stringify(reminders))

    setShowToast(true)
  }

  return (
    <EventDetails
      eventDetails={eventDetails}
      loading={loading}
      user={user}
      handleRsvpForRegisteredUser={handleRsvpForRegisteredUser}
      handleSaveVibe={handleSaveVibe}
      handleShareEvent={handleShareEvent}
      setReminderHandler={setReminderHandler}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
      savedEvent={savedEvent}
      isJoinedChatRoom={isJoinedChatRoom}
    />
  )
}

export default EventDetailsContainer
