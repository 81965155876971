import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'
import { createEvent, uploadImage, generateEventFlyer, getEventDataFromImage } from '../services/eventService'
import EventCreate from '../components/event/create/EventCreate'

function EventCreateContainer() {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    category: '',
    type: 0,
    tags: '',
    venueName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: 'USA',
    start: '',
    end: '',
    startDate: moment(new Date()).format('YYYY-MM-DD'),
    endDate: '',
    model: 'woman',
    ticketLink: '',
    shareable: 'N'
  })

  const navigate = useNavigate()

  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedName, setSelectedName] = useState('')
  const [imageURL, setImageURL] = useState('')
  const [address, setAddress] = useState(null)
  const [street, setStreet] = useState(null)
  const [city, setCity] = useState(null)
  const [state, setState] = useState(null)
  const [zip, setZip] = useState(null)
  const [disableButton, setDisableButton] = useState(false)
  const [imageLoading, setImageLoading] = useState('')
  const [errors, setErrors] = useState({
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  })
  // eslint-disable-next-line no-unused-vars
  const [venueAddress, setVenueAddress] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState('Loading...')
  const [images, setImages] = useState([])
  const [analyzeStatus, setAnalyzeStatus] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading('')
    }, 2000)

    return () => clearTimeout(timeout)
  }, [])

  const handleFileChange = async (file, value = '') => {
    if (value === 'text-to-image') {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    }

    setDisableButton(true)
    setSelectedFile(file)

    if (file && file.name) {
      setSelectedName(file.name)
    }

    const formData = new FormData()
    if (file instanceof File) {
      formData.append('image', file)
    } else {
      formData.append('imageUrl', file)
    }

    try {
      if (value === 'text-to-image') {
        setLoading('Uploading image...')
      } else if (value === 'image-to-text') {
        setImageLoading('Uploading image...')
      }
      const url = await uploadImage(formData)
      console.log('uploadImage url:', url.data)

      if (value === 'text-to-image') {
        setImageLoading('')
      }

      if (value === 'image-to-text') {
        setImageLoading('Analyzing...')
        const data = {
          image_url: url.data.imageUrl
        }
        const response = await getEventDataFromImage(JSON.stringify(data))
        console.log('getEventDataFromImage response:', response)
        setAnalyzeStatus(true)
        if (response.success) {
          const event = response.event
          setFormData((prevFormData) => ({
            ...prevFormData,
            name: event.name || prevFormData.name,
            description: event.description || prevFormData.description,
            category: event.category || prevFormData.category,
            tags: event.tags || prevFormData.tags,
            venueName: event.venue_name || prevFormData.venueName,
            street: event.street || prevFormData.street,
            city: event.city || prevFormData.city,
            state: event.state || prevFormData.state,
            zip: event.zip || prevFormData.zip,
            country: event.country || prevFormData.country,
            start: moment(event.start).format('hh:mm A') || prevFormData.start,
            end: moment(event.end).format('hh:mm A') || prevFormData.end,
            startDate: moment(event.start).format('YYYY-MM-DD') || prevFormData.startDate,
            endDate: moment(event.end).format('YYYY-MM-DD') || prevFormData.endDate
          }))
        } else {
          setMessage('Error analyzing image.')
          setShowToast(true)
        }
        setImageLoading('')
      }
      setImageURL(url.data.imageUrl)
      setMessage('Image uploaded successfully.')
      setShowToast(true)
    } catch (error) {
      setMessage('Error uploading image.')
      setShowToast(true)
      console.error('Error uploading the image:', error)
      setImageLoading('')
      setLoading('')
    } finally {
      setDisableButton(false)
      setImageLoading('')
      setLoading('')
    }
  }

  useEffect(() => {
    const validateDateTime = () => {
      const { startDate, endDate, start, end } = formData
      const format = 'YYYY-MM-DD hh:mm A'
      const timezone = 'America/New_York'

      let newErrors = {}

      if (startDate && endDate) {
        if (moment(startDate).isAfter(moment(endDate))) {
          newErrors.startDate = 'Start date cannot be later than end date'
          newErrors.endDate = 'End date cannot be earlier than start date'
        } else {
          newErrors.startDate = ''
          newErrors.endDate = ''

          if (start && end) {
            const startDateTime = moment.tz(`${startDate} ${start}`, format, timezone).utc()
            const endDateTime = moment.tz(`${endDate} ${end}`, format, timezone).utc()

            if (startDateTime.isAfter(endDateTime)) {
              newErrors.startTime = 'Start time cannot be later than end time'
              newErrors.endTime = 'End time cannot be earlier than start time'
            } else {
              newErrors.startTime = ''
              newErrors.endTime = ''
            }
          }
        }
      }

      setErrors(newErrors)
    }

    validateDateTime()
  }, [formData])

  useEffect(() => {
    console.log(errors)
  }, [errors])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: name === 'type' ? Number(value) : value
    }))

    if (name === 'state') {
      setState(value)
    }

    if (name === 'city') {
      setCity(value)
    }

    if (name === 'street') {
      setStreet(value)
    }

    if (name === 'zip') {
      setZip(value)
    }

    if (state !== null && city !== null && street !== null && zip !== null) {
      setAddress(`${street} ${city}, ${state} ${zip}`)
    }
  }

  const handleVenueSelected = (place) => {
    if (!place) return

    const address = place?.formatted_address || ''
    if (address) setVenueAddress(address)

    const addressComponents =
      place?.address_components?.reduce((acc, component) => {
        if (component.types.includes('street_number')) {
          acc.street_number = component.long_name
        } else if (component.types.includes('route')) {
          acc.route = component.long_name
        } else if (component.types.includes('locality')) {
          acc.city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
          acc.state = component.short_name
        } else if (component.types.includes('postal_code')) {
          acc.postal_code = component.long_name
        } else if (component.types.includes('country')) {
          acc.country = component.short_name
        }
        return acc
      }, {}) || {}

    setFormData((prevFormData) => ({
      ...prevFormData,
      venueName: place.name || '',
      street: addressComponents.street_number
        ? `${addressComponents.street_number} ${addressComponents.route || ''}`.trim()
        : '',
      city: addressComponents.city || '',
      state: addressComponents.state || '',
      zip: addressComponents.postal_code || '',
      country: addressComponents.country || ''
    }))
  }

  const handleInputBlur = (e) => {
    const { name, value } = e.target
    let newErrors = { ...errors }

    switch (name) {
      case 'name':
        newErrors.name = value.trim() === '' ? 'Event title is required' : ''
        break
      case 'category':
        newErrors.category = value === '' ? 'Category is required' : ''
        break
      case 'type':
        newErrors.type = value === '' ? 'Type is required' : ''
        break
      case 'tags':
        newErrors.tags = value === '' ? 'Tag is required' : ''
        break
      case 'street':
        newErrors.street = value.trim() === '' ? 'Street is required' : ''
        break
      case 'city':
        newErrors.city = value.trim() === '' ? 'City is required' : ''
        break
      case 'state':
        newErrors.state = value === '' ? 'State is required' : ''
        break
      case 'zip':
        newErrors.zip = value.trim() === '' ? 'Postal code is required' : ''
        break
      case 'startDate':
        newErrors.startDate = value.trim() === '' ? 'Start date is required' : ''
        break
      case 'endDate':
        newErrors.endDate = value.trim() === '' ? 'End date is required' : ''
        break
      case 'start':
        newErrors.startTime = value.trim() === '' ? 'Start time is required' : ''
        break
      case 'end':
        newErrors.endTime = value.trim() === '' ? 'End time is required' : ''
        break
      default:
        break
    }

    setErrors(newErrors)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    moment.tz.setDefault('America/New_York')
    setDisableButton(true)

    if (!selectedFile) {
      setMessage('Please select an image first.')
      setShowToast(true)
      setDisableButton(false)
      return
    }

    let flyerImageURL = ''

    if (selectedFile) {
      const formData = new FormData()
      if (selectedFile instanceof File) {
        formData.append('image', selectedFile)
      } else {
        formData.append('imageUrl', selectedFile)
      }

      try {
        setLoading('Uploading image...')
        const url = await uploadImage(formData)
        console.log('uploadImage url:', url.data)
        setImageLoading('')
        setImageURL(url.data.imageUrl)
        flyerImageURL = url.data.imageUrl
      } catch (error) {
        setMessage('Error uploading image.')
        setShowToast(true)
        console.error('Error uploading the image:', error)
        setImageLoading('')
        setLoading('')
      } finally {
        setDisableButton(false)
        setImageLoading('')
        setLoading('')
      }
    }

    try {
      setLoading('Creating event...')
      const startDateTime = moment
        .tz(`${formData.startDate} ${formData.start}`, 'YYYY-MM-DD hh:mm A', 'America/New_York')
        .format('YYYY-MM-DDTHH:mm:ss[Z]')

      const endDateTime = moment
        .tz(`${formData.endDate} ${formData.end}`, 'YYYY-MM-DD hh:mm A', 'America/New_York')
        .format('YYYY-MM-DDTHH:mm:ss[Z]')

      const eventData = {
        ...formData,
        start: startDateTime,
        end: endDateTime,
        promoter: JSON.parse(localStorage.getItem('user')).userId,
        url: imageURL || flyerImageURL
      }
      delete eventData.startDate
      delete eventData.endDate

      const resp = await createEvent(eventData)

      setMessage('Event created successfully.')
      setShowToast(true)
      setDisableButton(false)
      setLoading('')

      navigate(`/promoter/event/${resp.data.slug}`)
    } catch (error) {
      console.error('Error creating event:', error)
      setMessage('Error creating event.')
      setShowToast(true)
      setDisableButton(false)
      setLoading('')
    }
  }

  function isFormDataValid(formData) {
    const requiredKeys = ['name', 'description', 'category', 'tags']
    let isValid = true
    for (let key of requiredKeys) {
      if (formData[key] === '') {
        isValid = false
        setMessage(`Please fill out the ${key.toUpperCase()} information first.`)
        setShowToast(true)
        break
      }
    }
    return isValid
  }

  const generateFlyer = async () => {
    if (!isFormDataValid(formData)) {
      return
    }

    try {
      setLoading('Generating flyer...')
      setDisableButton(true)
      setImages([])
      const response = await generateEventFlyer(formData)
      const data = response && response.flyer && response.flyer.filter((item) => item)
      if (data && data.length > 0 && response.result === 'success') {
        setImages(data)
        setMessage('Flyer images generated successfully.')
        setShowToast(true)
      } else {
        setMessage(response.message || 'Something went wrong. Please try again.')
        setShowToast(true)
      }
      setDisableButton(false)
      setLoading('')
    } catch (error) {
      setDisableButton(false)
      console.error('Error generating flyer:', error)
      setMessage('Error generating flyer.')
      setShowToast(true)
      setLoading('')
    }
  }

  return (
    <EventCreate
      formData={formData}
      setFormData={setFormData}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
      selectedName={selectedName}
      handleFileChange={handleFileChange}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleVenueSelected={handleVenueSelected}
      address={address}
      disableButton={disableButton}
      imageLoading={imageLoading}
      errors={errors}
      handleInputBlur={handleInputBlur}
      setShowToast={setShowToast}
      showToast={showToast}
      toastMessage={message}
      loading={loading}
      generateFlyer={generateFlyer}
      images={images}
      imageURL={imageURL}
      setImageURL={setImageURL}
      analyzeStatus={analyzeStatus}
    />
  )
}

export default EventCreateContainer
