import React, { createContext, useContext, useRef } from 'react'

const ScrollContext = createContext()

export const useScroll = () => useContext(ScrollContext)

export const ScrollProvider = ({ children }) => {
  const scrollPositions = useRef({})

  const saveScrollPosition = (path, position) => {
    scrollPositions.current[path] = position
  }

  const getScrollPosition = (path) => {
    return scrollPositions.current[path] || 0
  }

  return <ScrollContext.Provider value={{ saveScrollPosition, getScrollPosition }}>{children}</ScrollContext.Provider>
}
