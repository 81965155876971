import React, { useEffect, useState } from 'react'
import { getActivities, getFollwersOfUser, getFollowingsOfUser } from '../../services/userService'
import Header from '../common/header/Header'

export default function Dashboard() {
  const [numOfEvents, setNumOfEvents] = useState(0)
  const [numOfConnectedPeople, setNumOfConnectedPeople] = useState(0)
  const _userInfo = localStorage.getItem('user')
  const userInfo = _userInfo ? JSON.parse(_userInfo) : null
  const fullName = _userInfo ? JSON.parse(_userInfo).fullName : ''
  const [activities, setActivities] = useState([])
  const [finalPoints, setfinalPoints] = useState(0)
  const [userFollowers, setUserFollowers] = useState([])
  const [userFollowings, setUserFollowings] = useState([])

  const fetchUserActivities = async () => {
    if (!userInfo) return
    const data = await getActivities(userInfo.userId)
    if (data.success) {
      setActivities(data.data)
      setNumOfEvents(data.data.length)
    }
  }
  const fetchUserFollowers = async () => {
    if (!userInfo) return
    const data = await getFollwersOfUser(userInfo.userId)
    if (data.success) {
      setUserFollowers(data.data)
    }
  }

  const fetchUserFollowings = async () => {
    if (!userInfo) return
    const data = await getFollowingsOfUser(userInfo.userId)
    if (data.success) {
      setUserFollowings(data.data)
    }
  }

  useEffect(() => {
    setNumOfConnectedPeople(userFollowers.length + userFollowings.length)
  }, [userFollowings, userFollowers])

  useEffect(() => {
    fetchUserActivities()
    fetchUserFollowers()
    fetchUserFollowings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const points = 750 - (userInfo?.totalPoints ?? 0)
    setfinalPoints(points)
  }, [userInfo])

  return (
    <div className="max-w-4xl mx-auto py-6 sm:px-6 lg:px-8 px-3">
      <Header />
      <h1 className="my-4 text-2xl font-semibold text-gray-900">Dashboard</h1>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
        <div className="sm:col-span-full">
          <div className="flex">
            <img
              src={userInfo?.profileImgUrl || '/assets/images/default_pfp.png'}
              alt="Profile"
              className="h-36 w-36 object-cover rounded-lg"
            />
            <div className="mx-4">
              <h3 className="text-xl font-medium leading-6 text-gray-900">{fullName}</h3>
              <p className="mt-2 max-w-2xl text-sm text-gray-800">Current Status</p>
              <p className="mt-1 py-1 rounded-md bg-purple-700 max-w-2xl  text-white text-center text-xl">
                {userInfo?.status}
              </p>
            </div>
          </div>
        </div>
        <div className="sm:col-span-full">
          <div className="bg-[#96f468] p-6 rounded-lg shadow-md">
            <h2 className="text-3xl font-bold mb-4 text-center">Congratulations!</h2>

            <div className="mt-4">
              <p className="text-3xl font-bold mb-4 text-center">
                {`You've Earned ${userInfo?.totalPoints ?? 0} Points`}
              </p>
              <p className="text-xl text-center">{`Only ${finalPoints} Points To VIP Status`}</p>
            </div>
          </div>
        </div>

        <div className="sm:col-span-full">
          <h2 className="text-xl text-center font-semibold">
            {`You\`ve attended ${numOfEvents} events and connected with ${numOfConnectedPeople} People.`}
            <br /> Way To Go!
          </h2>
        </div>

        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">{`Your Activities (${activities.length})`}</h2>
            <div className="mt-2 text-sm text-gray-500 ">
              <div className="flex gap-3 overflow-x-scroll">
                {activities.map((activity, index) => (
                  <div className="rounded-lg">
                    <img src={activity.flyer} alt={activity.name} className="h-16 w-16 object-cover rounded-xl" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">{`Following (${userFollowings?.length})`}</h2>
            <p className="mt-2 max-w-2xl text-sm text-gray-500">
              <div className="flex gap-3 overflow-x-scroll">
                {userFollowings.map((userFollowing, index) => (
                  <div className="rounded-lg max-w-16" key={index}>
                    <img
                      src={userFollowing?.User?.Profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                      alt={userFollowing?.username}
                      className="h-16 w-16 object-cover rounded-full"
                    />
                    <div class="relative group">
                      <div className="mt-2 overflow-x-hidden">{userFollowing?.User?.Profile?.username}</div>
                      <div class="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 shadow-lg">
                        {userFollowing?.User?.Profile?.username}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>

        <div className="sm:col-span-full">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-medium leading-6 text-gray-900">{`Followers (${userFollowers?.length})`}</h2>
            <p className="mt-2 max-w-2xl text-sm text-gray-500">
              <div className="flex gap-3 overflow-x-scroll">
                {userFollowers.map((userFollower, index) => (
                  <div className="rounded-lg max-w-16" key={index}>
                    <img
                      src={userFollower?.User?.Profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                      alt={userFollower?.username}
                      className="h-16 w-16 object-cover rounded-full"
                    />
                    <div class="relative group">
                      <div className="mt-2 overflow-x-hidden">{userFollower?.User?.Profile?.username}</div>
                      <div class="absolute bottom-full mb-2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 shadow-lg">
                        {userFollower?.User?.Profile?.username}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
