import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Toasts from '../common/toasts/Toasts'
import { GoogleOAuthProvider } from '@react-oauth/google'
import GoogleSignIn from './GoogleSignIn'
import config from './../../config/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { LoadingIndicator } from '../common/loading'
import Header from '../common/header/Header'

function Login({
  onSubmit,
  loading,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  setShowToast,
  showToast,
  toastMessage,
  googleLogin
}) {
  const [user, setUser] = useState(null)
  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword)
  }

  useEffect(() => {
    if (user) {
      googleLogin(user)
    }
  }, [googleLogin, user])

  if (loading) {
    return <LoadingIndicator />
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(formData)
  }

  return (
    <div id="login" className="grid w-3/4 place-content-center">
      <div className="w-full">
        <Header showLink={false} auth="unauthenticated" />
        <p className="mb-6 py-2 text-sm text-center">Log in to your GreatNight account to explore events.</p>
        <main>
          <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="text-sm">
                Email
              </label>
              <input
                type="email"
                style={{ borderRadius: '10px' }}
                className="text-sm  w-full p-2.5 bg-slate-50"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Enter email"
                required
              />
            </div>

            {errors?.email && <div className="text-sm text-red-500 mb-2">{errors?.email}</div>}

            <div className="mt-6 mb-4">
              <label htmlFor="cell" className="text-sm">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  style={{ borderRadius: '10px' }}
                  className="text-sm rounded-lg w-full p-2.5 bg-slate-50"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  placeholder="Enter password"
                  required
                />
                <button
                  type="button"
                  onClick={handleTogglePassword}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </button>
              </div>
            </div>
            {errors?.password && <div className="text-sm text-red-500 mb-2">{errors?.password}</div>}

            <p className="mb-4 text-end">
              <Link
                to="/forgot-password"
                className="text-decoration-none inter-400 text-sm text-primary"
                id="forgot-password-link"
              >
                Forgot the password?
              </Link>
            </p>
            <button
              type="submit"
              style={{ borderRadius: '20px' }}
              className="text-white w-full button button_large button--secondary"
              id="continue"
            >
              Sign in
            </button>

            <div className="inter-600 text-center my-4 text-xs font-normal">OR</div>

            <GoogleOAuthProvider clientId={config.GOOGLE_AUTH_CLIENT_ID}>
              <GoogleSignIn setUser={setUser} />
            </GoogleOAuthProvider>
          </form>
        </main>

        <footer id="footer">
          <p className="text-center mt-5">
            <span className="inter-400 text-sm">Don't have an account? </span>
            <Link to="/register" className="text-decoration-none text-sm inter-700" id="sign-up-link">
              Sign up
            </Link>
          </p>
        </footer>
      </div>
    </div>
  )
}

export default Login
