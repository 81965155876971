import React from 'react'
import Header from '../common/header/Header'
const TermsOfService = () => {
  return (
    <div className="container">
      <Header auth="unauthenticated" showLink={true} />
      <div className="flex gap-2 flex-wrap">
        <h1 data-custom-class="title" className="text-center text-2xl w-full font-bold mb-2">
          Terms of Service for GreatNight
        </h1>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          1. Introduction
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          These Terms of Service ("Terms", "ToS") govern your use of the GreatNight mobile application and website
          (collectively or individually, "Service") operated by GreatNight ("us", "we", or "our").
        </p>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          2. Accounts
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          When you create an account with us, you must provide information that is accurate, complete, and current at
          all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of
          your account on our Service.
        </p>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          3. Intellectual Property
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          The Service and its original content, features, and functionality are and will remain the exclusive property
          of GreatNight and its licensors.
        </p>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          4. Links To Other Web Sites
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          Our Service may contain links to third-party web sites or services that are not owned or controlled by
          GreatNight. GreatNight has no control over, and assumes no responsibility for, the content, privacy policies,
          or practices of any third party web sites or services.
        </p>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          5. Termination
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          We may terminate or suspend your account immediately, without prior notice or liability, for any reason
          whatsoever, including without limitation if you breach the Terms.
        </p>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          6. Governing Law
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          These Terms shall be governed and construed in accordance with the laws of the jurisdiction of GreatNight's
          operations, without regard to its conflict of law provisions.
        </p>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          7. Changes
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
        </p>

        <h2 data-custom-class="heading_1" className="text-lg font-semibold">
          8. Contact Us
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          If you have any questions about these Terms, please contact us at support@greatnight.us.
        </p>
      </div>
    </div>
  )
}

export default TermsOfService
