import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getEventsForUser } from '../../../../services/eventService'
import { getTickets } from '../../../../services/ticketService'
import { format, parseISO } from 'date-fns'
import { LoadingIndicator } from '../../../common/loading'
import Header from '../../../common/header/Header'
import { joinToChatRoom } from '../../../../services/chatService'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode, faMessage } from '@fortawesome/free-solid-svg-icons'
import useMessageStore from '../../../../store/messageStore'

function MyEvents() {
  const location = useLocation()
  const navigate = useNavigate()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const userId = user?.userId
  const isPromoterRoute = location.pathname.startsWith('/promoter')
  const activeTab = 'upcoming'

  const [loading, setLoading] = useState(true)
  const [events, setEvents] = useState([])
  const [visibleEventNum, setVisibleEventNum] = useState(5)
  const [displayedEvents, setDisplayedEvents] = useState([])
  const [tickets, setTickets] = useState([])
  const [visibleTicketNum, setVisibleTicketNum] = useState(5)
  const [displayedTickets, setDisplayedTickets] = useState([])
  // eslint-disable-next-line
  const notifications = useMessageStore((state) => state.notifications)
  const unreadMessages = useMessageStore((state) => state.unreadMessages)

  const formatDate = (date) => {
    return format(date, 'MMMM d, yyyy')
  }

  const handleSeeMore = (value) => {
    if (value === 'event') {
      setVisibleEventNum(visibleEventNum + 5)
      setDisplayedEvents(events.slice(0, visibleEventNum + 5))
    }

    if (value === 'ticket') {
      setVisibleTicketNum(visibleTicketNum + 5)
      setDisplayedTickets(tickets.slice(0, visibleTicketNum + 5))
    }
  }

  const handleJoinGroupChat = async (eventId) => {
    const payload = {
      eventId: eventId,
      userId: JSON.parse(localStorage.getItem('user'))?.userId
    }
    const res = await joinToChatRoom(payload)
    if (res.success) {
      navigate(`/chatroom/${res.roomId}`)
    }
  }

  useEffect(() => {
    const getEvents = async () => {
      if (user && user.userId && user.role) {
        const response = await getEventsForUser(user.userId)
        if (response.success) {
          setEvents(response.data.events)
          setDisplayedEvents(response.data.events.slice(0, visibleEventNum))
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
    const fetchTickets = async () => {
      try {
        const ticketsData = await getTickets(activeTab, userId)

        const sortedArray = ticketsData?.tickets.sort((a, b) => new Date(a.Event.start) - new Date(b.Event.start))
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const filteredArray = sortedArray.filter((item) => {
          const itemDate = new Date(item.Event.start)

          return itemDate >= today
        })

        setTickets(filteredArray)
        setDisplayedTickets(ticketsData.tickets.slice(0, visibleTicketNum))
      } catch (error) {
        console.log('Failed to fetch tickets:', error)
      } finally {
        setLoading(false)
      }
    }
    getEvents()
    fetchTickets()
    // eslint-disable-next-line
  }, [])

  if (loading) {
    return <LoadingIndicator />
  }

  const renderTicketActions = (ticket, index) => <div className="col-span-2 flex"></div>

  return (
    <div id="events" className="container pt-4">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <Header />
          <main>
            {/* Tickets Section */}
            <div className="mt-4">
              <div className="self-stretch text-[#04070A] font-roboto text-[20px] normal-case font-medium leading-[28px]">
                Events You're Attending
              </div>
              <div className="my-3">
                <span className="self-stretch text-[#525252] font-roboto text-[14px] normal-case font-normal leading-[155%]`">
                  Here's the list of events that you are attending. When you arrive, open the QR code and find other
                  GreatNighters to scan you so you can earn points!
                </span>
              </div>

              {/* Tickets List */}
              {tickets.length > 0 ? (
                <div className="space-y-4 mb-2 p-1">
                  {displayedTickets.map((ticket, index) => {
                    const numOfNotificationForEvent = unreadMessages.find(
                      (m) => m?.roomId === ticket.Event?.Chatroom?.id
                    )?.unreadCount
                    return (
                      <div key={`ticket-${ticket.rsvp_id}`} className="ticket">
                        <div className="flex flex-row items-center gap-1 w-full">
                          {ticket.Event?.flyer && (
                            <div className="w-1/2 min-w-[100px]">
                              <Link to={`${ticket.Event.slug}`} className="">
                                <img
                                  src={ticket.Event.flyer}
                                  alt={ticket.Event.name}
                                  className="w-full h-48 object-contain"
                                />
                              </Link>
                            </div>
                          )}
                          <div className="w-1/2 min-w-[250px]">
                            <div className="grid grid-cols-12">
                              <h3 className="col-span-10 font-extrabold">{ticket.Event?.name}</h3>
                              {renderTicketActions(ticket, index)}
                            </div>
                            <p className="text-sm mb-2">
                              {ticket?.Event?.start && formatDate(parseISO(ticket.Event.start))}
                            </p>
                            <div className="col-span-2 flex">
                              <div>
                                <p className="mr-4">Scan Code</p>
                                <Link
                                  to={`/event-goer/event/tickets/${ticket.rsvp_id}`}
                                  key={`qr-${index}`}
                                  className="mr-2"
                                >
                                  <FontAwesomeIcon className="ml-4 size-7" icon={faBarcode} />
                                </Link>
                              </div>

                              <div>
                                <p>Join Chat</p>
                                <div className="relative inline-flex">
                                  <Link
                                    onClick={() => handleJoinGroupChat(ticket.Event.event_id)}
                                    key={`chat-${index}`}
                                    className="underline decoration-sky-500"
                                  >
                                    <FontAwesomeIcon className="ml-4 size-7" icon={faMessage} />
                                  </Link>
                                  {numOfNotificationForEvent > 0 && (
                                    <span class="absolute top-0 right-0.5 grid min-h-[24px] min-w-[24px] translate-x-2/4 -translate-y-2/4 place-items-center rounded-full bg-red-600 py-1 px-1 text-xs text-white">
                                      {numOfNotificationForEvent}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mb-2" />
                      </div>
                    )
                  })}

                  {visibleTicketNum < tickets.length && (
                    <button
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none"
                      onClick={() => handleSeeMore('ticket')}
                    >
                      See more
                    </button>
                  )}
                </div>
              ) : (
                <div className="text-center mt-4">
                  <p className="font-bold">You have no tickets.</p>
                </div>
              )}
            </div>

            {/* Promoter Events Section */}
            {isPromoterRoute && (
              <div className="mt-4">
                <div className="row mt-3 mb-3">
                  <div className="col">
                    <span className="self-stretch text-[#525252] font-roboto text-[14px] normal-case font-normal leading-[155%]">
                      Below are the events that you have created and promoted. To see RSVPs for each event, click the
                      flyer.
                    </span>
                  </div>
                </div>

                {displayedEvents.length === 0 ? (
                  <div className="mt-5 mb-5 text-center">You have no events.</div>
                ) : (
                  <div className="space-y-4">
                    {displayedEvents.map((event) => (
                      <div key={event.event_id} className="flex items-center space-x-4">
                        {/* Image Column */}
                        <div className="w-24 h-24 flex-shrink-0">
                          {event.flyer && (
                            <img src={event.flyer} className="w-full h-full object-cover rounded shadow" alt="Event" />
                          )}
                        </div>

                        {/* Details Column */}
                        <div className="flex-1">
                          <Link to={`${event.slug}`} className="ticket text-decoration-none">
                            <div className="ticket-details">
                              <h3 className="text-lg font-semibold">{event.name}</h3>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))}

                    {visibleEventNum < events.length && (
                      <button
                        type="button"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 w-full mt-3"
                        onClick={() => handleSeeMore('event')}
                      >
                        See more
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </main>
        </div>
      </div>
    </div>
  )
}

export default MyEvents
