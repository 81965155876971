import React from 'react'
import Toasts from '../common/toasts/Toasts'
import { LoadingIndicator } from '../common/loading'
import Header from '../common/header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

function ForgotPassword({
  onSubmit,
  loading,
  formData,
  handleChange,
  handleInputBlur,
  errors,
  setShowToast,
  showToast,
  toastMessage
}) {
  if (loading) {
    return <LoadingIndicator />
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(formData)
  }

  return (
    <div className="container">
      <div id="forgot-password" className="w-full">
        <Header auth="unauthenticated" showLink={true} />
        <main>
          <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
          <form onSubmit={handleSubmit}>
            <div className="form-container">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <span className="form-icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <input
                type="email"
                className="form-input"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleInputBlur}
                placeholder="Enter email"
                required
              />
            </div>
            <button
              type="submit"
              className="button button--secondary button_large text-white w-full mt-6"
              id="continue"
              style={{ marginBottom: '15px' }}
            >
              Send One Time Password
            </button>
          </form>
        </main>
      </div>
    </div>
  )
}

export default ForgotPassword
