import React from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

const GoogleSignIn = ({ setUser }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`
        }
      })

      setUser({ success: true, data: userInfo.data })
    },
    onError: (error) => {
      console.error('Login Failed:', error)
      setUser({ success: false })
    }
  })

  return (
    <div>
      <button
        type="button"
        style={{ borderRadius: '15px' }}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        id="google-sign-in"
        onClick={() => googleLogin()}
      >
        <FontAwesomeIcon icon={faGoogle} />
        &nbsp; Continue with Google
      </button>
    </div>
  )
}

export default GoogleSignIn
