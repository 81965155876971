import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { formatEventTime } from '../../../services/eventService'
import { LoadingIndicator } from '../../common/loading'
import Header from '../../common/header/Header'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { joinToChatRoom } from '../../../services/chatService'
import { faMessage } from '@fortawesome/free-regular-svg-icons'

function TicketDetails({ user, isRegistered, ticketData, error, loading }) {
  const navigate = useNavigate()

  if (loading) {
    return <LoadingIndicator />
  }
  const handleJoinChat = async () => {
    const payload = {
      eventId: ticketData?.event?.Event?.event_id,
      userId: JSON.parse(localStorage.getItem('user'))?.userId
    }
    const res = await joinToChatRoom(payload)
    if (res.success) {
      navigate(`/chatroom/${res.roomId}`)
    }
  }

  return (
    <div id="ticket-details" className="container">
      <Header showLink={true} />
      <main className="ps-5 pe-5">
        <div className="mb-3 text-center">
          <img
            src={ticketData?.ticket}
            alt={ticketData?.event?.name}
            id="ticket-qr-code"
            className="img-thumbnail p-0 w-100"
          />
        </div>
        <div className="flex justify-center">
          <Link
            onClick={handleJoinChat}
            className="w-full text-center self-center text-white text-sm text-decoration-none rounded-3xl bg-[#65568f] hover:bg-[#7e6bb3] btn"
            id="join-chat"
          >
            <FontAwesomeIcon icon={faMessage} className="mr-1" />
            Join The Group Chat
          </Link>
        </div>
        <h2 className="mt-4 mb-1 inter-700">Event Details</h2>
        <p className="mb-2 inter-400 date-time">
          {formatEventTime(ticketData?.event?.Event?.start, ticketData?.event?.Event?.end)}
        </p>
        <h3 className="mb-0 mt-4 inter-700">{ticketData?.event?.Event?.venue_name}</h3>
        <p className="mb-2 inter-300 location">
          {ticketData?.event?.Event?.street}, {ticketData?.event?.Event?.city}, {ticketData?.event?.Event?.state},{' '}
          {ticketData?.event?.Event?.country}
        </p>
        <p className="mb-4 mt-4 inter-300" dangerouslySetInnerHTML={{ __html: ticketData?.event?.description }}></p>
      </main>
    </div>
  )
}

export default TicketDetails
