import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatEventDate } from '../../../services/eventService'

export const EventCard = ({ event, showFollowButton, onFollowUpdate, showUserName }) => {
  const [isFollowing, setIsFollowing] = useState(event?.User?.is_following || false)

  const handleFollowChange = (userInfo) => {
    setIsFollowing((prev) => !prev)
    const data = {
      action: isFollowing ? 'unfollow' : 'follow',
      user_id: userInfo
    }
    if (onFollowUpdate) {
      onFollowUpdate(data)
    } else {
      console.warn('You must pass an event handler to this function')
    }
  }
  if (!event) return null

  return (
    <div className="my-6 event relative max-w-[720px] mx-auto">
      {event.flyer && (
        <Link to={`/promoter/vibes/${event.user_id}`} className="text-decoration-none">
          <div className="flex items-center justify-between mb-2">
            {/* Left side: Event Name, Venue, City */}
            <div className="flex items-center space-x-2 flex-1 text-wrap">
              <h5 className="text-lg font-semibold text-gray-900 epilogue-600 line-clamp-1 ml-3">{event.name}</h5>
              {/* <span className="text-xs text-capitalize text-gray-400 inter-400 whitespace-nowrap">
                {event.venue_name ? `${event.venue_name}, ` : ''} {event.city}
              </span> */}
            </div>

            {/* Right side: User Info and Follow Button */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2 mr-3">
                <img
                  className="w-8 h-8 rounded-full"
                  src={event.User?.Profile?.profile_picture_url || '/assets/images/default_pfp.png'}
                  alt="Avatar"
                />
                {showUserName && <span className="text-sm text-gray-900 whitespace-nowrap">{event.User?.name}</span>}
              </div>

              {showFollowButton && (
                <button
                  className="text-primary whitespace-nowrap"
                  onClick={() => handleFollowChange(event.user_id, event.name)}
                  type="button"
                >
                  {isFollowing ? (
                    <span>
                      <h5 className="text-sm">Unfollow</h5>
                    </span>
                  ) : (
                    <span>
                      <h5 className="text-sm mr-2">Follow</h5>
                    </span>
                  )}
                </button>
              )}
            </div>
          </div>

          {/* Image Section with Overlay */}
          <div className="relative mb-12">
            <div className="z-30 card-img-top rounded-3">
              <img src={event.flyer} alt={event.name || 'Event'} />
            </div>
            <div className="overlay absolute top-0 left-0 p-2 z-40">
              <button className="items-left justify-center text-white border border-gray-200 font-semibold bg-gray-500 opacity-70 rounded hover:bg-blue-800">
                {formatEventDate(event.start)}
              </button>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}
