import React from 'react'
import { Outlet } from 'react-router-dom'
import ReactGA from 'react-ga'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { UserProvider } from './hooks/UserContext'
import { SocketProvider } from './hooks/SocketContext'
import Navigation from './components/common/navigation/Navigation'
import './App.css'
import { ScrollProvider } from './hooks/ScrollContext'

const TRACKING_ID = 'G-4FS6LBKBVY'
ReactGA.initialize(TRACKING_ID)

function App() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [location])

  return (
    <div className="App flex">
      <UserProvider>
        <SocketProvider>
          <ScrollProvider>
            <link href="tailwind.css" rel="stylesheet"></link>
            <div className="pb-[100px] w-full flex justify-center">
              <Outlet />
            </div>
            <Navigation />
          </ScrollProvider>
        </SocketProvider>
      </UserProvider>
      <ToastContainer />
    </div>
  )
}

export default App
