import React, { useState } from 'react'
import './FlyerThumbnails.css'

const FlyerThumbnails = (props) => {
  const [selectedImage, setSelectedImage] = useState(null)

  const handleThumbnailClick = (imageUrl) => {
    setSelectedImage(imageUrl)
    props.setSelectedFile(imageUrl)
  }

  return (
    <div>
      <p className="text-sm">
        Select one of the thumbnails below to be the background image for your flyer. Once you're satisfied, click Save
        to add your event to GreatNight.
      </p>
      <div className="thumbnail-container d-flex gap-3">
        {props.images.map((image, index) => (
          <button
            type="button"
            key={index}
            onClick={() => handleThumbnailClick(image)}
            className="cursor-pointer p-0 border-none bg-none"
          >
            <img src={image} alt={`Thumbnail ${index + 1}`} className="h-auto" />
          </button>
        ))}
      </div>

      {(selectedImage || props.selectedFile) && (
        <>
          <div className="full-image-container">
            <img src={selectedImage || props.selectedFile} alt="Full size" />
          </div>
        </>
      )}
    </div>
  )
}

export default FlyerThumbnails
