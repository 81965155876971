import React, { useCallback, useRef, useState } from 'react'

import './EventEdit.css'
import Header from '../../common/header/Header'
import Map from '../../common/map/Map'
import { LoadingIndicator } from '../../common/loading'
import AddressAutocomplete from '../../common/address-autocomplete/AddressAutocomplete'
import Toasts from '../../common/toasts/Toasts'
import DOMPurify from 'dompurify'
import Editor from '../../common/editor/Editor'
import { generateTimeOptions } from '../../../utils/timeUtils'

function EventEdit({
  formData,
  setFormData,
  selectedFile,
  selectedName,
  handleFileChange,
  handleChange,
  handleSubmit,
  handleVenueSelected,
  address,
  disableButton,
  imageLoading,
  errors,
  handleInputBlur,
  setShowToast,
  showToast,
  toastMessage,
  loading,
  imageURL
}) {
  const quillRef = useRef(null)
  const timeOptions = generateTimeOptions()

  const handleTextChange = useCallback(() => {
    const quill = quillRef.current
    const description = quill.root.innerHTML
    const sanitizedDescription = DOMPurify.sanitize(description)

    setFormData((prevFormData) => ({
      ...prevFormData,
      description: sanitizedDescription
    }))
  }, [setFormData])

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="event-edit" className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <header>
            <Header title="" showLink={true} />
          </header>
          <main>
            <Toasts show={showToast} message={toastMessage} onClose={() => setShowToast(false)} />
            <div className="self-stretch text-[#04070A] font-roboto text-[20px] normal-case font-medium leading-[31px] tracking[-0.2px]">
              Edit Event
            </div>
            <span className="text-gray-600 font-roboto text-sm normal-font font-normal leading-[155%]">
              Update the details of your event and/or upload a new flyer.
            </span>
            <form onSubmit={handleSubmit}>
              <div className="mt-3 mb-3">
                <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                  Event Title
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  value={formData.name}
                  placeholder="Event event title"
                  onChange={handleChange}
                  required
                  onBlur={handleInputBlur}
                />
                <div className="text-sm text-red-500">{errors?.name}</div>
              </div>
              <div className="mb-3">
                <div className="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                  Event Description
                </div>
                <Editor ref={quillRef} defaultValue={formData.description} onTextChange={handleTextChange} />
                {errors.description && <span className="error">{errors.description}</span>}

                <input type="hidden" name="description" value={formData.description} onChange={handleChange} />
              </div>
              {/* <div className="mb-3">
                <label htmlFor="category d-none" className="form-label">
                  Category
                </label>
                <select
                  className="form-select"
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  
                >
                  {EVENT_CATEGORIES.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.name}
                    </option>
                  ))}
                </select>
                <div className="text-sm text-red-500">{errors?.category}</div>
              </div> */}
              {/* <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Type
                </label>
                <select
                  className="form-select"
                  id="type"
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                >
                  <option value="1">Public</option>
                  <option value="0">Private</option>
                </select>
                <div className="text-sm text-red-500">{errors?.type}</div>
              </div> */}
              <div className="mb-3">
                <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                  Shareable
                </div>
                <span className="text-gray-600 font-roboto text-sm normal-font font-normal leading-[155%]">
                  Do you want others to be able to share this event, or keep it private?
                </span>
                <div className="flex space-x-4 text-sm">
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="share"
                      name="shareable"
                      value="Y"
                      checked={formData.shareable === 'Y'}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                      required
                    />
                    <label className="form-check-label" htmlFor="share">
                      Share
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id="notShareable"
                      name="shareable"
                      value="N"
                      checked={formData.shareable === 'N'}
                      onChange={handleChange}
                      onBlur={handleInputBlur}
                      required
                    />
                    <label className="form-check-label" htmlFor="notShareable">
                      Not Share
                    </label>
                  </div>
                  <div className="form-text text-danger">{errors?.shareable}</div>
                </div>
              </div>
              <div className="mb-3">
                <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                  Tags
                </div>
                <span className="text-gray-600 font-roboto text-sm normal-font font-normal leading-[155%]">
                  Improve discoverability of your event by adding tags relevant to the subject matter.
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="tags"
                  name="tags"
                  value={formData.tags}
                  placeholder="Tags"
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                />
                <div className="text-sm text-red-500">{errors?.tags}</div>
                <div className="mt-3 mb-3">
                  <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                    Ticket Link - Add ticket from Eventbrite etc.
                  </div>

                  <input
                    type="text"
                    className="form-control"
                    id="ticketLink"
                    name="ticketLink"
                    value={formData.ticketLink}
                    placeholder="Add ticket link"
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                  />
                </div>
              </div>
              <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                Venue
              </div>
              {address && (
                <div className="mb-3">
                  <label htmlFor="venueLocation" className="form-label">
                    Venue Location
                  </label>
                  <Map address={address} />
                </div>
              )}
              <div className="mb-3">
                <AddressAutocomplete onPlaceSelected={handleVenueSelected} value={formData.venueName} />
              </div>
              <div className="mb-3">
                <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                  Street
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="street"
                  name="street"
                  value={formData.street}
                  placeholder="Street "
                  onChange={handleChange}
                  onBlur={handleInputBlur}
                  required
                />
                <div className="text-sm text-red-500">{errors?.street}</div>
              </div>
              {/* <div className="mb-3 row">
                <div className="col">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={formData.city}
                    placeholder="City"
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                  />
                  <div className="text-sm text-red-500">{errors?.city}</div>
                </div>
              </div> */}
              <div className="mb-3 row">
                {/* <div className="col">
                  <label htmlFor="state" className="form-label">
                    State/Province
                  </label>
                <select
                    className="form-select"
                    id="state"
                    name="state"
                    value={formData.state}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                >
                  {EVENT_STATES.map((state) => (
                    <option key={state.value} value={state.value}>
                      {state.name}
                    </option>
                  ))}
                </select>
                <div className="text-sm text-red-500">{errors?.state}</div>
              </div> */}
                <div className="mb-3 row">
                  {/* <div className="col">
                  <label htmlFor="zip" className="form-label">
                    Postal Code
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="zip"
                    name="zip"
                    value={formData.zip}
                    placeholder="Postal Code"
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                  />
                  <div className="text-sm text-red-500">{errors?.zip}</div>
                </div> */}
                </div>
              </div>
              {/* <div className="mb-3">
                <label htmlFor="country" className="form-label">
                  Country
                </label>
                <select
                  className="form-select"
                  id="country"
                  name="country"
                  value={formData.country}
                  onChange={handleChange}

                  disabled
                >
                  <option value="USA">United States</option>
                </select>
              </div> */}

              <div className="mb-3 row">
                <div className="col">
                  <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                    Start Date
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    name="startDate"
                    value={formData.startDate}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="MM/DD/YYYY"
                    aria-describedby="validationStartDateFeedback"
                    required
                  />
                  <div id="validationStartDateFeedback" className="text-danger">
                    {errors?.startDate}
                  </div>
                </div>
                <div className="col">
                  <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                    End Date
                  </div>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    name="endDate"
                    value={formData.endDate}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    placeholder="MM/DD/YYYY"
                    required
                    aria-describedby="validationEndDateFeedback"
                    disabled={!formData?.startDate}
                  />
                  <div id="validationEndDateFeedback" className="text-danger">
                    {errors?.endDate}
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <div className="col">
                  <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                    Start Time
                  </div>
                  <select
                    className="form-control"
                    id="start"
                    name="start"
                    value={formData.start}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                    aria-describedby="validationStartFeedback"
                    disabled={!formData?.endDate}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  <div id="validationStartFeedback" className="text-danger">
                    {errors?.startTime}
                  </div>
                </div>
                <div className="col">
                  <div class="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                    End Time
                  </div>
                  <select
                    className="form-control"
                    id="end"
                    name="end"
                    value={formData.end}
                    onChange={handleChange}
                    onBlur={handleInputBlur}
                    required
                    aria-describedby="validationEndFeedback"
                    disabled={!formData?.start}
                  >
                    <option value="">Select Time</option>
                    {timeOptions.map((time) => (
                      <option key={time} value={time}>
                        {time}
                      </option>
                    ))}
                  </select>
                  <div id="validationStartFeedback" className="text-danger">
                    {errors?.endTime}
                  </div>
                </div>
              </div>
              {/* <div className="mb-3">
                <label htmlFor="model" className="form-label">
                  Model
                </label>
                <p className="mt-1 mb-2">Who do you want to be featured in your autogenerated flyer</p>
                <div className="form-check form-group">
                  {EVENT_MODELS.map((model) => (
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id={model.id}
                        name={model.name}
                        value={model.value}
                        checked={formData.model === model.value}
                        onChange={handleChange}
                        onBlur={handleInputBlur}
                        
                      />
                      <label className="form-check-label" htmlFor={model.id}>
                        {model.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="text-sm text-red-500">{errors?.model}</div>
              </div> */}

              <h3 className="inter-700 mb-3">Event Image</h3>

              <div id="event-img">
                {imageURL && <img src={imageURL} className="img-fluid w-100 rounded-3 mb-3" alt="Event Flyer" />}
              </div>
              <div>
                <span className="overflow-hidden text-[#04070A] text-ellipsis font-roboto text-sm font-semibold leading-normal">
                  Upload new flyer here.
                </span>

                <FileUploader selectedName={selectedName} onFileSelect={handleFileChange} imageLoading={imageLoading} />
              </div>

              <button type="submit" className="btn mt-4" id="continue" disabled={disableButton}>
                Continue
              </button>
            </form>
          </main>
        </div>
      </div>
    </div>
  )
}

const FileUploader = ({ onFileSelect, selectedName, imageLoading }) => {
  const [dragOver, setDragOver] = useState(false)
  const [thumbnail, setThumbnail] = useState(null)

  const [dragOverCounter, setDragOverCounter] = useState(0)
  const fileInputRef = useRef(null)

  const handleDragEnter = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragOverCounter((prev) => prev + 1)
  }, [])

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragOver(false)

      setDragOverCounter((prev) => prev - 1)
      if (dragOverCounter <= 1) {
        setDragOverCounter(0)
      }
    },
    [dragOverCounter]
  )

  const handleDragOver = useCallback((e) => {
    setDragOver(true)
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      setDragOver(false)
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        onFileSelect(e.dataTransfer.files[0])
        generateThumbnail(e.dataTransfer.files[0])
      }
    },
    [onFileSelect]
  )

  const handleChange = useCallback(
    (e) => {
      if (e.target.files && e.target.files[0]) {
        onFileSelect(e.target.files[0])
        generateThumbnail(e.target.files[0])
      }
    },
    [onFileSelect]
  )

  const triggerFileInputClick = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const generateThumbnail = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setThumbnail(e.target.result)
    }
    reader.readAsDataURL(file)
  }

  return (
    <div
      className={`file-upload ${dragOver ? 'drag-over' : ''}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={triggerFileInputClick}
    >
      {imageLoading ? (
        <div>
          <div className="d-flex justify-content-center align-items-center mt-2">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Uploading...</span>
            </div>
          </div>
          <div className="uploading-text">Uploading...</div>
        </div>
      ) : (
        <>
          {thumbnail ? (
            <img src={thumbnail} alt="thumbnail" className="thumbnail" />
          ) : (
            <img src="/assets/images/icons/file.svg" alt="upload" />
          )}
          <h3> {selectedName || 'Drag and drop a file or click here to select a file'}</h3>
          <p className="mt-3">JPEG or PNG, no larger than 10MB. Recommended size is 500x500.</p>
        </>
      )}

      <input type="file" ref={fileInputRef} onChange={handleChange} style={{ display: 'none' }} />
    </div>
  )
}
export default EventEdit
