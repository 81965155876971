import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Cookies from 'js-cookie'
import Profile from '../components/profile/Profile'
import { changePassword, getUserFollowStats, getUserStatus, updateProfile } from '../services/userService'
import { showToast as showToastModal } from '../services/toastService'

function ProfileContainer() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))

  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')
  const [follows, setFollows] = useState('')

  const [formData, setFormData] = useState({
    email: '',
    currentPassword: '',
    password: '',
    confirmPassword: '',
    username: user?.username
  })
  const [errors, setErrors] = useState({
    email: '',
    currentPassword: '',
    password: '',
    confirmPassword: ''
  })

  useEffect(() => {
    if (user) formData.email = user.email
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [formData, user])

  const getUserStatusData = async () => {
    const userToken = Cookies.get('gn_userToken')
    if (!userToken) return
    try {
      const result = await getUserStatus(userToken, user.userId)
      if (result.success) {
        setStatus(result.data)
      }
    } catch (error) {
      setMessage(`Error getting user status: ${error}`)
      setShowToast(true)
    }
  }
  const getUserFollowsData = async () => {
    try {
      const result = await getUserFollowStats(user.userId)
      if (result.success) {
        setFollows(result.data)
      }
    } catch (error) {
      setMessage(`Error getting user status: ${error}`)
      setShowToast(true)
    }
  }

  useEffect(() => {
    if (user?.userId) {
      getUserStatusData()
      getUserFollowsData()
    }
    // eslint-disable-next-line
  }, [user?.userId])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleInputBlur = (e) => {
    const { name, value } = e.target
    let newErrors = { ...errors }

    switch (name) {
      case 'email':
        newErrors.email = value === '' ? 'Email is required' : ''
        break
      case 'currentPassword':
        newErrors.currentPassword = value === '' ? 'Current password is required' : ''
        break
      case 'password':
        newErrors.password = value === '' ? 'New Password is required' : ''
        break
      case 'confirmPassword':
        newErrors.confirmPassword = value === '' ? 'Confirm password is required' : ''
        break
      default:
        break
    }

    if (name === 'confirmPassword' && value !== formData.password) {
      newErrors.confirmPassword = 'Passwords do not match'
    }

    setErrors(newErrors)
  }

  const handleSubmit = async (formData) => {
    const { email, currentPassword, password } = formData
    setLoading(true)

    try {
      const result = await changePassword(email, currentPassword, password)
      if (result) setMessage(result.message)
      setShowToast(true)
      setLoading(false)

      if (result.success) {
        setFormData({
          currentPassword: '',
          password: '',
          confirmPassword: ''
        })
      }
    } catch (error) {
      setMessage(`Error signing in user: ${error}`)
      setShowToast(true)
      setLoading(false)
    }
  }

  const onUpdateProfile = async () => {
    const { username } = formData
    setLoading(true)
    try {
      const res = await updateProfile({ username })
      if (res.success) {
        showToastModal('Profile updated successfully', 'success')
        const user = JSON.parse(localStorage.getItem('user'))
        localStorage.setItem('user', JSON.stringify({ ...user, username }))
      } else {
        showToastModal(res.message, 'error')
      }
    } catch (error) {
      showToastModal('Failed to update profile', error.message)
    }
    setLoading(false)
  }

  return (
    <Profile
      loading={loading}
      onSubmit={handleSubmit}
      user={user}
      formData={formData}
      handleChange={handleChange}
      handleInputBlur={handleInputBlur}
      errors={errors}
      setShowToast={setShowToast}
      onUpdateProfile={onUpdateProfile}
      showToast={showToast}
      toastMessage={message}
      status={status}
      follows={follows}
    />
  )
}

export default ProfileContainer
