import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useScroll } from '../../../hooks/ScrollContext'

export default function Header(props) {
  const navigate = useNavigate()
  const location = useLocation()
  const { event, auth, showLink, title } = props
  const { saveScrollPosition, getScrollPosition } = useScroll()

  const handleBackArrow = () => {
    const currentPage = window.location.pathname
    saveScrollPosition(currentPage, window.scrollY)

    if (event?.invite) {
      navigate(`/event-goer/event/${event?.invite?.event}/influencer/${event?.invite?.influencerId}`)
    } else {
      navigate(-1)
    }
  }

  // Save scroll position when unmounting
  useEffect(() => {
    const handleScroll = () => {
      saveScrollPosition(location.pathname, window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [location.pathname, saveScrollPosition])

  // Restore scroll position on navigation
  useEffect(() => {
    const position = getScrollPosition(location.pathname)

    window.scrollTo(0, position, { behavior: 'smooth' })
  }, [location.pathname, getScrollPosition])

  if (auth === 'unauthenticated') {
    return (
      <header className="pt-4">
        {showLink && (
          <button onClick={handleBackArrow}>
            <img src="/assets/images/icons/left-arrow.svg" alt="Nevigate to event details" />
          </button>
        )}
        <div className="text-center mb-4">
          <img src="/assets/images/logo.png" alt="GreatNight Logo" />
          <h2 id="tagline">Your Social Life Just Got Smarter</h2>
        </div>
        {event?.invite && (
          <div>
            <h2 className="mb-1 text-center inter-600">Get Your Ticket!</h2>
            <h3 className="mb-5 text-center inter-600">{event.invite?.eventName}</h3>
          </div>
        )}
      </header>
    )
  } else {
    return (
      <header id="primary-header" className="container pt-4">
        <div className="grid grid-cols-12 mb-5 gap-2">
          <div className="col-span-7">
            <img
              src="/assets/images/logo.png"
              alt="GreatNight Logo"
              id="logo"
              className="max-w-[250px]"
              onClick={() => navigate('/promoter/')}
            />
          </div>
          <div className="col-span-5 flex items-center justify-end gap-3">
            {/*  <FontAwesomeIcon icon={faBell} className="visually-hidden" />
            <FontAwesomeIcon
              icon={faCircleQuestion}
              id="icons"
              className="icon-hover"
              onClick={() => navigate('/promoter/help')}
            />
            <FontAwesomeIcon icon={faCamera} className="icon-hover" onClick={() => navigate('/promoter/my-event')} />
            <FontAwesomeIcon icon={faUser} className="icon-hover" onClick={() => navigate('/promoter/profile')} /> */}
            <FontAwesomeIcon
              icon={faCircleQuestion}
              id="icons"
              className="icon-hover"
              onClick={() => navigate('/promoter/help')}
            />

            <i className="fa-solid fa-user" onClick={() => navigate('/promoter/profile')}></i>
          </div>
        </div>
        <div className="flex justify-between items center mb-4 relative">
          {showLink && (
            <button onClick={handleBackArrow} className="flex items-center gap-2">
              <FontAwesomeIcon icon={faChevronLeft} className="block text-black" />
              <span className="block">Back</span>
            </button>
          )}
          {title && <h3 className="inter-700 font-light uppercase">{title}</h3>}
        </div>
      </header>
    )
  }
}
