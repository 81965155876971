import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { getUpcomingEvents } from '../../../services/eventService'
import AdComponent from '../ads'
import Header from '../../common/header/Header'
import { LoadingIndicator } from '../../common/loading'
import { EventCard } from '../card'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function Events() {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))

  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState([])
  const [allTags, setAllTags] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [selectedTag, setSelectedTag] = useState(null)
  const [selectedDay, setSelectedDay] = useState(null)
  const [thisWeekEvents, setThisWeekEvents] = useState([])
  const [nextWeekEvents, setNextWeekEvents] = useState([])
  const [futureEvents, setFutureEvents] = useState([])

  const dayOfWeekMapping = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6
  }

  useEffect(() => {
    const getEvents = async () => {
      if (user && user.userId && user.role) {
        const response = await getUpcomingEvents(user.userId)
        if (response.success) {
          const combinedEvents = response.data.events
          setEvents(combinedEvents)
          setFilteredEvents(combinedEvents)
          if (Array.isArray(response.data.tags)) {
            setTags(Array.from(new Set(response.data.tags)))
            setAllTags(Array.from(new Set(response.data.tags)))
          }
          setLoading(false)
        } else {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }

    getEvents()
    // eslint-disable-next-line
  }, [])

  const applyFilters = (tag) => {
    if (tag === null) {
      setFilteredEvents(events)
      setTags(allTags)
      return
    }

    let filtered = []

    if (dayOfWeekMapping[tag] !== undefined) {
      const selectedDay = tag.charAt(0).toUpperCase() + tag.slice(1)
      setSelectedDay(selectedDay)

      const now = moment()
      const startOfWeek = now.clone().startOf('week')
      const endOfWeek = now.clone().endOf('week')
      const startOfNextWeek = startOfWeek.clone().add(1, 'week')
      const endOfNextWeek = endOfWeek.clone().add(1, 'week')

      let thisWeekEvents = []
      let nextWeekEvents = []
      let futureEvents = []

      events.forEach((event) => {
        const eventDate = moment(event.start)
        const eventDay = eventDate.format('dddd')

        if (eventDay !== selectedDay) return

        if (eventDate.isBetween(startOfWeek, endOfWeek, null, '[]')) {
          thisWeekEvents.push(event)
        } else if (eventDate.isBetween(startOfNextWeek, endOfNextWeek, null, '[]')) {
          nextWeekEvents.push(event)
        } else if (eventDate.isAfter(endOfNextWeek)) {
          futureEvents.push(event)
        }
      })

      setThisWeekEvents(thisWeekEvents)
      setNextWeekEvents(nextWeekEvents)
      setFutureEvents(futureEvents)

      filtered = [...thisWeekEvents, ...nextWeekEvents, ...futureEvents]
    } else {
      filtered = events.filter((event) =>
        event.tags
          .split(',')
          .map((t) => t.trim())
          .includes(tag)
      )
    }

    setFilteredEvents(filtered)

    const filteredTags = [...new Set(filtered.flatMap((event) => event.tags.split(',').map((t) => t.trim())))]

    setTags(filteredTags.length > 0 ? filteredTags : allTags)
  }

  const filterEventsByTag = (tag) => {
    if (tag === null) {
      setThisWeekEvents([])
      setNextWeekEvents([])
      setFutureEvents([])
    }
    setSelectedTag(tag)
    applyFilters(tag)
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="events">
      <Header />
      <main>
        <div className="container">
          {tags.length > 0 && (
            <>
              <div className="tag-btn-container">
                <span className="font-bold mb-3 block">Select a tag to filter</span>
                {selectedTag && (
                  <button id="clear_tag" className="text-sm mb-2" onClick={() => filterEventsByTag(null)}>
                    <FontAwesomeIcon icon={faTimes} /> Clear Filter
                  </button>
                )}
              </div>
              <div className="flex flex-wrap gap-2 max-h-20 mb-8 overflow-scroll">
                <div className="w-full">
                  {tags.map((tag, index) => (
                    <span
                      key={index}
                      onClick={() => filterEventsByTag(tag)}
                      className={`p-1 bg-neutral-100 rounded text-sm text-neutral-600 inline-block cursor-pointer ${selectedTag === tag ? 'selected' : ''}`}
                    >
                      #{tag}
                    </span>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
        {dayOfWeekMapping[selectedTag] !== undefined && (
          <>
            {thisWeekEvents.length > 0 && (
              <>
                <hr className="mt-5" />
                <h6 className="epilogue-400 text-center mt-3 mb-3">This Week {selectedDay}</h6>
                <hr className="mb-3" />
                {thisWeekEvents.map((event) => (
                  <EventCard event={event} />
                ))}
              </>
            )}

            {nextWeekEvents.length > 0 && (
              <>
                <hr className="mt-5" />
                <h6 className="epilogue-400 text-center mt-3 mb-3">Next Week {selectedDay}</h6>
                <hr className="mb-3" />
                {nextWeekEvents.map((event) => (
                  <EventCard event={event} />
                ))}
              </>
            )}

            {futureEvents.length > 0 && (
              <>
                <hr className="mt-5" />
                <h6 className="epilogue-400 text-center mt-3 mb-3">Future Events {selectedDay}</h6>
                <hr className="mb-3" />
                {futureEvents.map((event) => (
                  <EventCard event={event} />
                ))}
              </>
            )}
          </>
        )}

        {dayOfWeekMapping[selectedTag] === undefined &&
          filteredEvents.length > 0 &&
          filteredEvents.map((event, index) => (
            <div key={index}>
              <EventCard event={event} />
              {(index + 1) % 5 === 1 && <AdComponent index={index} />}
            </div>
          ))}
        {filteredEvents.length === 0 &&
          thisWeekEvents.length === 0 &&
          nextWeekEvents.length === 0 &&
          futureEvents.length === 0 && (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
              <p className="text-center">
                There are no events based on the filters you selected, please select another.
              </p>
            </div>
          )}
      </main>
    </div>
  )
}

export default Events
