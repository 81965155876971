import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getVibesList } from '../../services/eventService'
import Header from '../common/header/Header'
import { showToast } from '../../services/toastService'
import { followUser, unfollowUser } from '../../services/userService'
import { EventCard } from './card/'
import { LoadingIndicator } from '../common/loading'

const VibesList = () => {
  const location = useLocation()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [vibes, setVibes] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getVibes = async () => {
      const response = await getVibesList()
      if (response.success) {
        if (response.data.vibes.length > 0) {
          setVibes(response.data.vibes)
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    }
    getVibes()
    // eslint-disable-next-line
  }, [])

  const handleFollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot follow yourself', 'error')
      }
      const response = await followUser(user.userId, follower_id)
      if (response.success) {
        showToast(`You are now following ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            event.Event.User.is_following = 1
          }
          return event
        })
        setVibes(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const handleUnfollow = async (follower_id, name) => {
    if (user && user.userId) {
      if (user.userId === follower_id) {
        showToast('You cannot unfollow yourself', 'error')
      }
      const response = await unfollowUser(user.userId, follower_id)
      if (response.success) {
        showToast(`You have unfollowed ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            if (event.Event && event.Event.User) {
              event.Event.User.is_following = 0
            }
          }
          return event
        })
        setVibes(updatedEvents)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const VibeSection = ({ vibes }) =>
    vibes?.length > 0 && (
      <>
        <hr className="mt-5" />
        <hr className="mb-3" />
        {vibes.map((vibe, index) => (
          <EventCard
            key={index}
            event={vibe.Event}
            onFollowUpdate={(data) =>
              data.action === 'unfollow'
                ? handleUnfollow(data.user_id, data.name)
                : handleFollow(data.user_id, data.name)
            }
            showFollowButton={false}
            showUserName={true}
          />
        ))}
      </>
    )

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <div id="vibeslist">
      <Header title="Vibes" showLink={true} />
      <main className="">
        <div>
          <h2 className="text-sm ml-2 mr-2 text-center">
            Find people to follow based on their vibe and things they recommend.
          </h2>
        </div>
        {vibes?.length > 0 ? (
          <VibeSection vibes={vibes} />
        ) : (
          <div className="mt-10 text-center">
            <h2 className="text-center">No vibes found</h2>
          </div>
        )}
      </main>
    </div>
  )
}

export default VibesList
