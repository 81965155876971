import api from './api'

export const getInterests = async () => {
  try {
    const response = await api.get('/interests')
    console.log('API Response:', response)
    console.log('Data returned:', response?.data)
    return response?.data ?? []
  } catch (error) {
    return []
  }
}
