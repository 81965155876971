import React from 'react'
import Header from '../common/header/Header'

function PrivacyPolicy() {
  return (
    <div className="container">
      <Header auth="unauthenticated" showLink={true} />
      <div className="flex flex-wrap gap-4">
        <h1 data-custom-class="title" className="text-2xl font-bold">
          Privacy Policy for GreatNight
        </h1>{' '}
        <br />
        <p data-custom-class="subtitle" className="w-full">
          Effective date: March 07, 2024
        </p>
        <p data-custom-class="body_text" className="mb-2">
          GreatNight ("us", "we", or "our") operates the GreatNight mobile application (the "Service"). This page
          informs you of our policies regarding the collection, use, and disclosure of personal data when you use our
          Service and the choices you have associated with that data. We use your data to provide and improve the
          Service. By using the Service, you agree to the collection and use of information in accordance with this
          policy.
        </p>
        <h2 data-custom-class="heading_1" className="font-semibold text-lg">
          Information Collection And Use
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          We collect several different types of information for various purposes to provide and improve our Service to
          you.
        </p>
        <h3 data-custom-class="heading_2" className="font-semibold text-lg">
          Types of Data Collected
        </h3>
        <p data-custom-class="body_text" className="mb-2">
          <strong>Personal Data</strong>
          <br />
          While using our Service, we may ask you to provide us with certain personally identifiable information that
          can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but
          is not limited to:
          <ul className="list-disc mt-2 ml-6 text-sm leading-7">
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Cookies and Usage Data</li>
          </ul>
        </p>
        <h2 data-custom-class="heading_1" className="font-semibold text-lg">
          Use of Data
        </h2>
        <p data-custom-class="body_text" className="mb-2">
          GreatNight uses the collected data for various purposes:
          <ul className="list-disc mt-2 ml-6 text-sm leading-7">
            <li>To provide and maintain the Service</li>
            <li>To notify you about changes to our Service</li>
            <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
            <li>To provide customer care and support</li>
            <li>To provide analysis or valuable information so that we can improve the Service</li>
            <li>To monitor the usage of the Service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
        </p>
        <h2 data-custom-class="heading_1" className="font-semibold text-lg">
          Contact Us
        </h2>
        <p data-custom-class="body_text" className="mb-2 w-full">
          If you have any questions about this Privacy Policy, please contact us:
          <ul>
            <li>By email: support@greatnight.com</li>
          </ul>
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
