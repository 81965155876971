import React, { useRef, useState, useEffect, useContext } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSmile, faPaperclip } from '@fortawesome/free-solid-svg-icons'
import EmojiPicker from 'emoji-picker-react'
import {
  fetchMessages,
  fetchParticipants,
  getChatRoomDetails,
  leaveChatroom,
  reportMessage,
  updateReadStatusForRoom
} from '../../services/chatService'
import { showToast } from '../../services/toastService'
import { classNames } from '../../utils'
import useMessageStore from '../../store/messageStore'
import { SocketContext } from '../../hooks/SocketContext'

export default function ChatRoom() {
  const user = JSON.parse(localStorage.getItem('user'))
  const userId = user?.userId
  const { roomId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const chatContainerRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [eventName, setEventName] = useState(location.state?.eventName)
  const [participants, setParticipants] = useState([])
  const [userIdProfileImgDict, setUserIdProfileImgDict] = useState({})
  const [newMessage, setNewMessage] = useState('')
  const [isEmojiKeyboradVisible, setIsEmojiKeyboradVisible] = useState(false)
  const { socket } = useContext(SocketContext)
  const resetUnreadCountForRoom = useMessageStore((state) => state.resetUnreadCountForRoom)
  const _messages = useMessageStore((state) => state.messages)
  const messages = _messages[roomId] || []
  const setMessages = useMessageStore((state) => state.setMessages)
  const toggleNotification = useMessageStore((state) => state.toggleNotification)
  const isNotificationEnabled = useMessageStore((state) => state.isNotificationEnabled)

  useEffect(() => {
    if (roomId) {
      if (!eventName) {
        fetchChatRoomDetails(roomId)
      } else {
        initChatRoom()
      }
    }
    // eslint-disable-next-line
  }, [eventName, roomId])

  const fetchChatRoomDetails = async (roomId) => {
    try {
      const data = await getChatRoomDetails(roomId)
      if (data.success) {
        setEventName(data.room.Event.name)
      }
    } catch (error) {
      console.error('Error fetching event details', error)
    }
  }

  const fetchMessagesForRoom = async () => {
    setIsLoading(true)
    const data = await fetchMessages(roomId)
    if (data.success) {
      setMessages(roomId, data.messages)
      scrollToBottom()
      socket.emit('join_room', {
        roomId: roomId,
        userId: userId
      })
    } else {
      showToast(data.message || 'Something went wrong', 'error')
    }
    setIsLoading(false)
  }

  const fetchParticipantsForRoom = async () => {
    try {
      const data = await fetchParticipants(roomId)
      if (data.success) {
        setParticipants(data.participants)
        setUserIdProfileImgDict(
          data.participants.reduce((acc, participant) => {
            acc[participant.User.user_id] = participant.User.Profile.profile_picture_url
            return acc
          }, {})
        )
      } else {
        showToast(data.message || 'Something went wrong', 'error')
      }
    } catch (error) {
      console.error('Error fetching participants', error)
      showToast('Something went wrong', 'error')
    }
  }

  useEffect(() => {
    if (roomId) {
      const getData = setTimeout(async () => {
        await updateReadStatusForRoom(roomId)
        resetUnreadCountForRoom(roomId)
      }, 3000)
      return () => clearTimeout(getData)
    }
    // eslint-disable-next-line
  }, [roomId])

  const initChatRoom = async () => {
    await fetchParticipantsForRoom()
    await fetchMessagesForRoom()
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatContainerRef?.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
        document.getElementById('messages-container').scrollTop =
          document.getElementById('messages-container').scrollHeight
      }
    }, 1000)
  }

  const onSendMessage = () => {
    const messageData = {
      roomId: roomId,
      sender_id: userId,
      text: newMessage,
      createdAt: new Date()
    }
    socket.emit('send_message', messageData)
    setNewMessage('')
    scrollToBottom()
    setIsEmojiKeyboradVisible(false)
  }

  const onLeaveChatroom = async () => {
    const response = await leaveChatroom(roomId, userId)
    if (response.success) {
      navigate('/')
    } else {
      showToast(response.message || 'Something went wrong', 'error')
    }
  }

  const onReport = async (message) => {
    const res = await reportMessage(message.chatroom_id, message.id)
    if (res.success) {
      showToast('Message reported successfully', 'success')
    } else {
      showToast(res.message || 'Something went wrong', 'error')
    }
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="spinner-grow" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div
      className="max-w-2xl p-4 mx-2 relative overflow-y-scroll h-[calc(100vh-150px)] shadow-md rounded-lg"
      ref={chatContainerRef}
      id="messages-container"
    >
      <div className="text-right">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
              <span className="sr-only">Open options</span>
              <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
            </MenuButton>
          </div>

          <MenuItems
            transition
            className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1">
              <MenuItem>
                <button
                  onClick={onLeaveChatroom}
                  className="block w-full px-4 py-2 text-left text-sm text-red-500 data-[focus]:bg-gray-100 data-[focus]:text-red-600 data-[focus]:outline-none"
                >
                  Leave Chatroom
                </button>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
      <div className="flex justify-center">
        <label className="font-bold text-2xl">{eventName}</label>
      </div>
      <div>
        <label className="font-bold text-xl">Group Chat</label>
        <div className="my-2 text-sm text-gray-700">
          Use this chat to find a friend, find specific items at event, like restroom or food
        </div>
        <div className="my-2 text-sm text-gray-700">Say hi and make new friends.</div>
      </div>

      <div className="flex gap-3 my-2">
        <div className="flex h-6 shrink-0 items-center">
          <div className="group grid size-4 grid-cols-1">
            <input
              id="comments"
              name="comments"
              type="checkbox"
              aria-describedby="comments-description"
              className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
              onChange={(e) => {
                toggleNotification()
              }}
              checked={!isNotificationEnabled || false}
            />
            <svg
              fill="none"
              viewBox="0 0 14 14"
              className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
            >
              <path
                d="M3 8L6 11L11 3.5"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="opacity-0 group-has-[:checked]:opacity-100"
              />
              <path
                d="M3 7H11"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="opacity-0 group-has-[:indeterminate]:opacity-100"
              />
            </svg>
          </div>
        </div>
        <div className="text-sm/6">
          <label htmlFor="comments" className="font-medium text-gray-900">
            Turn off notifications
          </label>
          <p id="comments-description" className="text-gray-500">
            You can still access chatroom, but will not receive notifications.
          </p>
        </div>
      </div>

      {participants.length > 0 && (
        <div className="w-[90%] mb-4 flex overflow-x-scroll flex-nowrap bg-[#d9d9d930] p-2 rounded-md">
          {participants.map((participant, index) => (
            <div key={index} className="mx-1">
              <img
                src={userIdProfileImgDict[participant.user_id] || '/assets/images/default_pfp.png'}
                alt=""
                className="w-[40px] h-[40px] rounded-full min-w-[40px] min-h-[40px] object-cover"
              />
              <h3 className="text-gray-500 text-xs font-normal text-center text-wrap">
                {participant?.User?.name?.split(' ')?.[0]}
              </h3>
            </div>
          ))}
        </div>
      )}
      <div>
        {messages.map((message, index) =>
          message.sender_id === userId ? (
            <div className="flex gap-2.5 justify-end mb-4" key={index}>
              <div className="">
                <div className="grid mb-2">
                  <div className="justify-end items-center inline-flex">
                    <h3 className="text-gray-500 text-xs font-normal leading-4 py-1">
                      {message.participant}
                      {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </h3>
                  </div>
                  <div className="px-3 py-2 bg-[#7424e3] rounded">
                    <h2 className="text-white text-xs font-normal leading-snug">{message.text}</h2>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid" key={index}>
              <div className="flex gap-2.5 mb-4">
                <div className="grid">
                  <div className="justify-start items-center inline-flex">
                    <h6 className="text-gray-500 text-xs font-normal leading-4 py-1">
                      <h5 className="text-gray-900 text-sm font-normal leading-snug pb-1">{message?.User?.name}</h5>
                      {new Date(message.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </h6>
                  </div>
                  <div className="group px-3.5 py-2 bg-[#c8ff32] rounded-sm justify-start items-center gap-3 inline-flex">
                    <div>
                      <p className="text-gray-600 text-sm font-normal leading-snug">{message.text}</p>
                    </div>
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400  opacity-0 invisible group-hover:visible group-hover:opacity-100 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                          <span className="sr-only">Open options</span>
                          <ChevronDownIcon aria-hidden="true" className="size-4" />
                        </MenuButton>
                      </div>

                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-24 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1">
                          <MenuItem>
                            <button
                              onClick={() => onReport(message)}
                              className="block w-full px-4 py-2 text-left text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                            >
                              Report
                            </button>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      {isEmojiKeyboradVisible && (
        <div className="!absolute !bottom-10 !right-0">
          <EmojiPicker
            onEmojiClick={(emoji) => {
              setNewMessage(newMessage + emoji.emoji)
            }}
          />
        </div>
      )}

      <div className="flex justify-center">
        <div className="w-[90%] md:w-3/4 p-2 max-w-2xl bg-white z-50 fixed bottom-[90px] mx-auto pl-3 pr-1 py-1 rounded-3xl border border-gray-200 items-center gap-4 inline-flex justify-between">
          <div className="flex flex-1 items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <g id="User Circle">
                <path
                  id="icon"
                  d="M6.05 17.6C6.05 15.3218 8.26619 13.475 11 13.475C13.7338 13.475 15.95 15.3218 15.95 17.6M13.475 8.525C13.475 9.89191 12.3669 11 11 11C9.6331 11 8.525 9.89191 8.525 8.525C8.525 7.1581 9.6331 6.05 11 6.05C12.3669 6.05 13.475 7.1581 13.475 8.525ZM19.25 11C19.25 15.5563 15.5563 19.25 11 19.25C6.44365 19.25 2.75 15.5563 2.75 11C2.75 6.44365 6.44365 2.75 11 2.75C15.5563 2.75 19.25 6.44365 19.25 11Z"
                  stroke="#4F46E5"
                  strokeWidth="1.6"
                />
              </g>
            </svg>
            <input
              className="flex-1 grow shrink basis-0 text-black text-md font-medium leading-4 focus:outline-none"
              placeholder="Type here..."
              onChange={(e) => {
                setNewMessage(e.target.value)
              }}
              value={newMessage}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSendMessage()
                }
              }}
            />
          </div>
          <div className="flex items-center gap-2">
            <FontAwesomeIcon icon={faPaperclip} className="mb-3 d-block text-black mt-3 cursor-pointer" />

            <FontAwesomeIcon
              icon={faFaceSmile}
              className={classNames(
                'mb-3 d-block mt-3 cursor-pointer',
                isEmojiKeyboradVisible ? ' text-yellow-600' : 'text-black'
              )}
              onClick={() => {
                setIsEmojiKeyboradVisible((prev) => !prev)
              }}
            />

            <button onClick={onSendMessage} className="items-center flex px-3 py-2 bg-indigo-600 rounded-md shadow ">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <g id="Send 01">
                  <path
                    id="icon"
                    d="M9.04071 6.959L6.54227 9.45744M6.89902 10.0724L7.03391 10.3054C8.31034 12.5102 8.94855 13.6125 9.80584 13.5252C10.6631 13.4379 11.0659 12.2295 11.8715 9.81261L13.0272 6.34566C13.7631 4.13794 14.1311 3.03408 13.5484 2.45139C12.9657 1.8687 11.8618 2.23666 9.65409 2.97257L6.18714 4.12822C3.77029 4.93383 2.56187 5.33664 2.47454 6.19392C2.38721 7.0512 3.48957 7.68941 5.69431 8.96584L5.92731 9.10074C6.23326 9.27786 6.38623 9.36643 6.50978 9.48998C6.63333 9.61352 6.72189 9.7665 6.89902 10.0724Z"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                  />
                </g>
              </svg>
              <h3 className="text-white text-xs font-semibold leading-4 px-2">Send</h3>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
