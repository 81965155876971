import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getVibesForUser } from '../../services/eventService'
import Header from '../common/header/Header'
import { showToast } from '../../services/toastService'
import { followUser, getUserProfileByUserId, unfollowUser } from '../../services/userService'
import { EventCard } from '../event/card/item'
import { LoadingIndicator } from '../common/loading'

function Vibes() {
  const location = useLocation()
  const userId = location.pathname.split('/').pop()
  const user = location.state?.user || JSON.parse(localStorage.getItem('user'))
  const [vibes, setVibes] = useState([])
  const [loading, setLoading] = useState(true)
  const [vibesUser, setVibesUser] = useState(null)

  const getVibes = async () => {
    if (userId) {
      const response = await getVibesForUser(userId)
      if (response.success) {
        if (response.data.vibes.length > 0) {
          setVibes(response.data.vibes)
          let tagsData = []
          response.data.vibes.forEach((element) => {
            tagsData.push(element.Event?.tags.split(','))
          })
        }
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }
  const getUserInfo = async () => {
    const myUserId = user?.userId
    if (userId && myUserId) {
      const response = await getUserProfileByUserId(myUserId, userId)
      if (response.success) {
        setVibesUser(response.data.data)
      } else {
        console.log(response.message)
      }
      setLoading(false)
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    getVibes()
    getUserInfo()
    // eslint-disable-next-line
  }, [userId])

  if (loading) {
    return <LoadingIndicator />
  }

  const handleFollow = async (follower_id, name) => {
    const userId = user?.userId
    if (userId) {
      if (userId === follower_id) {
        showToast('You cannot follow yourself', 'error')
      }
      const response = await followUser(userId, follower_id)
      if (response.success) {
        showToast(`You are now following ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            event.Event.User.is_following = 1
          }
          return event
        })
        setVibes(updatedEvents)
        setVibesUser({ ...vibesUser, follow: true })
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  const handleUnfollow = async (follower_id, name) => {
    const userId = user?.userId
    if (userId) {
      if (userId === follower_id) {
        showToast('You cannot unfollow yourself', 'error')
      }
      const response = await unfollowUser(userId, follower_id)
      if (response.success) {
        showToast(`You have unfollowed ${name}`, 'success')
        const updatedEvents = vibes.map((event) => {
          if (event.Event?.user_id === follower_id) {
            if (event.Event && event.Event.User) {
              event.Event.User.is_following = 0
            }
          }
          return event
        })
        setVibes(updatedEvents)
        setVibesUser({ ...vibesUser, follow: false })
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  return (
    <div id="events">
      <Header title="Vibes" showLink={true} />
      <main>
        <div className="flex justify-between">
          <div className="ml-4 auto-rows-max text-center flex items-center">
            <div className="col-span-2">
              <img
                src={
                  vibesUser?.profile?.profile_picture_url
                    ? vibesUser.profile.profile_picture_url
                    : '/assets/images/default_pfp.png'
                }
                alt="Profile"
                className="h-16 w-16 object-contain rounded-full mb-4"
              />
              <p className="col-span-2  text-2xl font-bold ">{vibesUser?.profile?.username}</p>
              <p className="text-left text-base">{vibesUser?.profile?.about_me}</p>
            </div>
          </div>
          <div className="justify-end">
            <button
              className="text-decoration-none button text-white block text-center m-4 mr-4 button--primary"
              type="button"
              onClick={() =>
                vibesUser?.follow
                  ? handleUnfollow(vibesUser?.profile?.user_id, vibesUser?.profile?.username)
                  : handleFollow(vibesUser?.profile?.user_id, vibesUser?.profile?.username)
              }
            >
              {vibesUser?.follow ? 'Unfollow' : 'Follow'}
            </button>
          </div>
        </div>

        {vibes?.length > 0 && (
          <>
            <hr className="mt-5" />
            <hr className="mb-3" />
            {vibes.map((vibe, index) => (
              <EventCard
                key={index}
                event={vibe.Event}
                onFollowUpdate={(data) =>
                  data.action === 'unfollow'
                    ? handleUnfollow(data.user_id, data.name)
                    : handleFollow(data.user_id, data.name)
                }
                showFollowButton={false}
                showUserName={false}
              />
            ))}
          </>
        )}
      </main>
    </div>
  )
}

export default Vibes
